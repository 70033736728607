<script lang="ts" setup>
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { CallToActionSectionRecord } from "~/graphql/datocms/generated";

defineProps<{ data: CallToActionSectionRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <div class="full-bleed 2xl:no-bleed relative overflow-hidden">
    <DatocmsImage v-if="data.backgroundImage" :data="data.backgroundImage.responsiveImage as ResponsiveImageType" :lazy-load="false" object-fit="cover" object-position="right" class="!absolute size-full sm:h-[150%] lg:h-full 2xl:rounded-2xl" />
    <Vector class="vector--background absolute right-[-10%] top-xl h-auto w-[200px] lg:bottom-[-25%] lg:right-xl lg:top-auto lg:w-[270px] xl:w-[340px]" />
    <div class="relative mx-xl flex h-full w-fit flex-col justify-center py-2xl md:mx-auto md:py-2xl lg:ml-[38%] lg:py-3xl xl:ml-[45%] xl:mr-2xl xl:py-4xl 2xl:py-5xl">
      <span class="mb-2xs text-xs font-normal uppercase tracking-widest text-th-neutral-400 xl:text-sm">{{ data.superTitle }}</span>
      <h3 class="mb-3">
        {{ data.title }}
      </h3>
      <div class="markdown-content mb-2xl text-md leading-7 xl:text-lg xl:leading-7" v-html="data.text" />
      <UButton v-if="data.pageLink.length" variant="solid" color="black" size="sm" class="w-fit font-normal" :to="localePath(`/${data.pageLink[0].pageLink.slug}`)">
        {{ data.pageLink[0].text }}
      </UButton>
    </div>
  </div>
</template>

<style scoped>
.vector--background {
  --esc-vector-fill-color: black;
  --esc-vector-mix-blend-mode: soft-light;
  --esc-vector-opacity: .3;
}
</style>
