<script lang="ts" setup>
import type { AllNewsSectionRecord, ImageFileField } from "~/graphql/datocms/generated";

defineProps<{ data: AllNewsSectionRecord }>();

const localePath = useLocalePath();
const { locale } = useI18n();

const pageSize = 18;
const page = ref(1);

const { data: newsData } = await useFetch("/api/news", {
  query: { pageSize: pageSize, page: page, locale: locale },
  deep: true,
});

// not required, but cleaner
const itemCount = computed(() => {
  return newsData.value?._allNewsMeta?.count ?? 0;
});
</script>

<template>
  <div id="news-list" class="scroll-mt-8">
    <div v-show="itemCount > 0" class="mb-lg grid grid-cols-1 gap-lg md:mb-3xl md:grid-cols-2 md:gap-md lg:grid-cols-3">
      <article v-for="news in newsData?.allNews" :key="news.id">
        <NuxtLink :to="localePath({ name: 'news-slug', params: { slug: news.slug } })">
          <TeaserCard :page-id="news.id" :image="news.teaserImage as ImageFileField" :title="news.title" :text="news.teaserText" />
        </NuxtLink>
      </article>
    </div>

    <div v-show="itemCount > pageSize" class="flex justify-center">
      <UPagination v-model="page" :page-count="pageSize" :total="itemCount">
        <template #prev="{ onClick }">
          <UTooltip text="Previous page">
            <UButton icon="i-ph-arrow-circle-left" class="size-10 rounded-md bg-th-neutral-50 text-th-neutral-500 rtl:[&_span:first-child]:rotate-180" @click="onClick" />
          </UTooltip>
        </template>

        <template #next="{ onClick }">
          <UTooltip text="Next page">
            <UButton icon="i-ph-arrow-circle-right" class="size-10 rounded-md bg-th-neutral-50 text-th-neutral-500 rtl:[&_span:last-child]:rotate-180" @click="onClick" />
          </UTooltip>
        </template>
      </UPagination>
    </div>
  </div>
</template>

<style scoped>

</style>
