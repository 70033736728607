<script setup lang="ts">
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

const props = defineProps<{ label: string, defaultOpen?: boolean }>();

const isOpen = ref(props.defaultOpen ?? false);

function toggleContent() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <div v-bind="$attrs">
    <div class="flex cursor-pointer items-baseline" @click="toggleContent">
      <span class="me-2">
        <Vector class="size-3 transition-transform duration-[400ms]" :class="[isOpen ? 'vector--visible rotate-[95deg]' : 'vector--hidden rotate-[50deg]']" />
      </span>
      <span class="font-semibold">{{ props.label }}</span>
    </div>
    <CollapseTransition :duration="400">
      <div v-show="isOpen">
        <slot />
      </div>
    </CollapseTransition>
  </div>
</template>

<style scoped>
.vector--visible {
  --esc-vector-fill-color: #73B7B6;
  --esc-vector-mix-blend-mode: normal;
  --esc-vector-opacity: 1;
}

.vector--hidden {
  --esc-vector-fill-color: #C81414;
  --esc-vector-mix-blend-mode: normal;
  --esc-vector-opacity: 1;
}
</style>
