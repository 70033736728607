<script lang="ts" setup>
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { ImageFileField, ProductGroupOverviewSectionRecord } from "~/graphql/datocms/generated";
import "core-js/actual/array/at";

defineProps<{ data: ProductGroupOverviewSectionRecord }>();

const localePath = useLocalePath();
</script>

<template>
  <div class="full-bleed border-0 border-b border-th-neutral-100 bg-gradient-to-b from-transparent to-[#EBE8E0]">
    <div class="container relative mx-auto">
      <div class="mx-md flex flex-col items-center">
        <div class="mb-2xl flex flex-wrap justify-center gap-md">
          <div v-for="group in data.groups" :key="group.id">
            <img :src="group.icon.url" alt="" class="size-[42px]" loading="lazy">
          </div>
        </div>

        <h2 class="mb-md text-center" v-html="data.title" />

        <div class="relative z-10 mb-lg w-full text-center lg:w-1/2 xl:w-1/3" v-html="data.text" />

        <UButton v-if="data.pageLink.at(0)" variant="solid" color="primary" size="md" :to="localePath(`/${data.pageLink.at(0)?.pageLink.slug}`)" class="relative z-10 mb-2xl 2xl:mb-3xl">
          {{ data.pageLink.at(0)?.text }}
        </UButton>

        <div class="block 2xl:hidden">
          <DatocmsImage v-if="data.footerImage as ImageFileField" :data="data.footerImage?.responsiveImage as ResponsiveImageType" :lazy-load="false" object-fit="cover" />
        </div>

        <div class="absolute bottom-0 hidden w-full justify-between 2xl:flex">
          <DatocmsImage v-if="data.footerImageLeft as ImageFileField" :data="data.footerImageLeft?.responsiveImage as ResponsiveImageType" :lazy-load="false" object-fit="cover" class="max-h-[200px]" />
          <DatocmsImage v-if="data.footerImageRight as ImageFileField" :data="data.footerImageRight?.responsiveImage as ResponsiveImageType" :lazy-load="false" object-fit="cover" class="max-h-[200px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
